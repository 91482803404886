import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import Amplify, * as AmplifyModules from 'aws-amplify'
import router from './router';
import { AmplifyPlugin } from 'aws-amplify-vue'
import aws_exports from './aws-exports'
Amplify.configure(aws_exports)
Vue.use(AmplifyPlugin, AmplifyModules)
// It's important that you instantiate the Vue instance after calling Vue.use!
new Vue({
  vuetify,
  router, 
  render: h => h(App)
}).$mount('#app')
