import Vue from 'vue'; 
import Router from 'vue-router'; 
import Home from './views/Home.vue'; 
import About from './views/About.vue'; 
import SignIn from './views/SignIn.vue'

Vue.use(Router); 

export default new Router({ 
  routes: [ 
    { 
      path: '/home', 
      name: 'home', 
      component: Home,
    }, 
    { 
      path: '/about', 
      name: 'about', 
      component: About, 
    }, 
    { 
      path: '/', 
      name: 'signin', 
      component: SignIn, 
    }, 
  ] 
});
