<template>
  <v-container>
    <p> {{ sayHi() }}</p>
    <v-btn @click="selectFile">
      SELECT A FILE !!
    </v-btn>
    <input style="display: none" 
      ref="input" type="file"
      @change="uploadSelectedFile()">
  </v-container>
</template>

<script>
import { Storage } from 'aws-amplify';
//  import { Auth, Storage } from 'aws-amplify';

export default {
  name: 'Home',
    data: () => ({
      msg: "ファイルをアップしてください", 
    }), 
    /*
    mounted: async function() {
      this.login();
    },
    */
  updated() {
    // setTimeoutで3000ms後に
    setTimeout(() => {
      this.msg = "ファイルをアップしてください"}
      ,5000
    )
  },
  methods:{
    /*
    login() {
      console.log("login.");
      Auth.signIn(this.loginid, this.loginpw)
        .then((data) => {
          if(data.challengeName == "NEW_PASSWORD_REQUIRED"){
            console.log("new password required.");
            data.completeNewPasswordChallenge(this.loginpw, {}, 
              {
                onSuccess(result) {
                    console.log("onSuccess");
                    console.log(result);
                },
                onFailure(err) {
                    console.log("onFailure");
                    console.log(err);
                }
              }
            );
          }
          console.log("login successfully.");
        }).catch((err) => {
          console.log("login failed.");
          console.log(err);
        });
    },
    */
    sayHi() {
      return this.msg;
    }, 

    selectFile() {
      if(this.$refs.input != undefined){
        this.$refs.input.click();
      }
    }, 

    uploadSelectedFile() {
      let file = this.$refs.input.files[0];
      let filename = file.name;
      if(file == undefined){
        return;
      }
      console.log(file);
      console.log(filename);
      //拡張子
      var pos = filename.lastIndexOf('.');
      if (pos === -1) return '';  
      var fileext = filename.slice(pos + 1)
      console.log(fileext);

      if (fileext === "csv"){
        let dt = new Date();
        let dirName = this.getDirString(dt);
        let filePath = dirName + "_" + file.name;
        Storage.put(filePath, file).then(result => {
          console.log(result);
        }).catch(err => console.log(err));
        this.msg = "ファイルをアップしました"
      }else{
        this.msg = "対象ファイルは csv のみです"
      }
    }, 

/*    
    //ファイル名から拡張子を取得する関数
    getExt(filename){
    var pos = filename.lastIndexOf('.');
    if (pos === -1) return '';
    return filename.slice(pos + 1);
    },

    //アップロード予定のファイル名の拡張子が許可されているか確認する関数
    checkExt(filename){
    //アップロードを許可する拡張子
    var allow_exts = new Array('jpg', 'jpeg', 'png');
    //比較のため小文字にする
    var ext = getExt(filename).toLowerCase();
    //許可する拡張子の一覧(allow_exts)から対象の拡張子があるか確認する
    if (allow_exts.indexOf(ext) === -1) return false;
    return true;
    },
*/

    getDirString(date){
      let random = date.getTime() + Math.floor(100000 * Math.random());
      random = Math.random() * random;
      random = Math.floor(random).toString(16);
      return "" + 
        ("00" + date.getUTCFullYear()).slice(-2) + 
        ("00" + (date.getMonth() + 1)).slice(-2) + 
        ("00" + date.getUTCDate()).slice(-2) + 
        ("00" + date.getUTCHours()).slice(-2) + 
        ("00" + date.getUTCMinutes()).slice(-2) + 
        ("00" + date.getUTCSeconds()).slice(-2) + 
        "-" + random;
    }, 
  }
}
</script>
