<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="onHome">HOME</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="onAbout">ABOUT</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="onSignIn">SIGN</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        Hiramoto Serve Project
      </v-toolbar-title> 
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app>
      <v-card-title>
        <v-btn fab small class="mx-2" color="white" href="https://show-hi.com/" target="_blank">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn fab small class="mx-2" color="white" href="https://twitter.com/ulitisnu78" target="_blank">
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>Copyright © Hiramoto Serve Project</p>
      </v-card-text>
    </v-footer>

  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    drawer: false,
  }),
  methods:{
    onHome() {
      if(this.$route.path != '/home'){
        this.$router.push({ path: 'home' });
      }
    }, 
    onAbout() {
      if(this.$route.path != '/about'){
        this.$router.push({ path: 'about' });
      }
    }, 
    onSignIn() {
      if(this.$route.path != '/'){
        this.$router.push({ path: '/' });
      }
    },
  }
};
</script>
