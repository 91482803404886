<template> 
  <div class="home">
  <Home /> 
  </div>
</template> 

<script> 
  import Home from '../components/Home' 
  export default { 
    components: { 
      Home 
    } 
  } 
</script> 
<style>
.home {
  margin: 20px;
}
input {
  font-size: 16px !important;
}
@media screen and (max-width: 480px) {
  .home div {
    min-width: 0;
  }
}
</style>
