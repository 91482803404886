<template>
  <v-container>
  <div class="signin">
    <div v-if="!signedIn">
      <amplify-authenticator v-bind:authConfig="authConfig"></amplify-authenticator>
    </div>
    <div v-if="signedIn">
    <p>Hello, How Are You Doing ?</p>
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="50"
        />
    <hello-world />
    <hr>
    <br>
    <router-link to="home" >link to Home</router-link><br>
    <router-link to="about" >link to About</router-link>
    <br>
    <br>
      <amplify-sign-out></amplify-sign-out>
    </div>
  </div>
  </v-container>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'
import HelloWorld from '../components/HelloWorld'
export default {
  name: 'SignIn',
  components: {
    HelloWorld,
  },
  data () {
    return {
      signedIn: false,
      authConfig: {
        signUpConfig: {
          hideAllDefaults: true,
          defaultCountryCode: '1',
          signUpFields: [
            {
              label: 'Username',
              key: 'username',
              required: true,
              displayOrder: 1,
              type: 'string',
            },
            {
              label: 'Password',
              key: 'password',
              required: true,
              displayOrder: 2,
              type: 'password'
            }
          ]
        }
      }
    }
  },
  async beforeCreate() {
    try {
      await Auth.currentAuthenticatedUser()
      this.signedIn = true
    } catch (err) {
      this.signedIn = false
    }
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        this.signedIn = true
      } else {
        this.signedIn = false
      }
    })
  },
  /*
  mounted: async function() {
    this.login();
  },
  methods:{
    selectFile() {
      if(this.$refs.input != undefined){
        this.$refs.input.click();
      }
    }, 

    uploadSelectedFile() {
      let file = this.$refs.input.files[0];
      if(file == undefined){
        return;
      }
      console.log(file);

      let dt = new Date();
      let dirName = this.getDirString(dt);
      let filePath = dirName + "/" + file.name;
      Storage.put(filePath, file).then(result => {
        console.log(result);
      }).catch(err => console.log(err));
    }, 

    getDirString(date){
      let random = date.getTime() + Math.floor(100000 * Math.random());
      random = Math.random() * random;
      random = Math.floor(random).toString(16);
      return "" + 
        ("00" + date.getUTCFullYear()).slice(-2) + 
        ("00" + (date.getMonth() + 1)).slice(-2) + 
        ("00" + date.getUTCDate()).slice(-2) + 
        ("00" + date.getUTCHours()).slice(-2) + 
        ("00" + date.getUTCMinutes()).slice(-2) + 
        ("00" + date.getUTCSeconds()).slice(-2) + 
        "-" + random;
    }, 
  }
*/
}
</script>

<style>
.signin {
  margin: 20px;
}
input {
  font-size: 16px !important;
}
@media screen and (max-width: 480px) {
  .signin div {
    min-width: 0;
  }
}
</style>