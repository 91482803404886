<template> 
  <div class="about">
  <About /> 
  </div>
</template> 

<script> 
  import About from '../components/About' 
  export default { 
    components: { 
      About 
    } 
  } 
</script> 
<style>
.about {
  margin: 20px;
}
input {
  font-size: 16px !important;
}
@media screen and (max-width: 480px) {
  .about div {
    min-width: 0;
  }
}
</style>
