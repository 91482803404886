/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:7119e1aa-83d1-447a-9a3d-7f706cd2fcde",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_3WULu9Tse",
    "aws_user_pools_web_client_id": "2eqive1qlqjl7ql538lqevuc89",
    "oauth": {},
    "aws_user_files_s3_bucket": "sample-vue-project-bucket113931-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
